import React from 'react';
import { useNavigate } from 'react-router-dom';

const About: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="page-container">
      <h1 className="main-title">About FruitCount</h1>
      <div className="form-container">
        <h2>Welcome to FruitCount</h2>
        <p>This is a placeholder about page to test React Router.</p>
        <button 
          onClick={() => navigate('/')}
          style={{
            marginTop: '20px',
            width: '100%',
            padding: '12px',
            backgroundColor: 'var(--primary-color)',
            color: 'white',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer'
          }}
        >
          Back to Sign In
        </button>
      </div>
    </div>
  );
};

export default About; 