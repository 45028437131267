import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
};

export const userPool = new CognitoUserPool(poolData);

console.log('AWS Config:', {
  region: process.env.REACT_APP_AWS_REGION,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID
});

export const awsConfig = {
  region: process.env.REACT_APP_AWS_REGION || 'eu-west-1',
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
};
