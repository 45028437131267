// src/App.tsx
import React, { ErrorInfo, ReactNode, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import SignIn from "./SignIn";
import About from "./About";
import "./App.css";

// Hash Handler Component
const HashHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // If we have a hash route from the worker, convert it to a normal route
    if (window.location.hash.startsWith('#/')) {
      const path = window.location.hash.slice(1);
      navigate(path, { replace: true });
    }
  }, []);

  return null;
};

class ErrorBoundary extends React.Component<{children: ReactNode}, {hasError: boolean}> {
  constructor(props: {children: ReactNode}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <Router>
        <HashHandler />
        <div className="app-container">
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
