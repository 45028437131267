import React, { useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { userPool, awsConfig } from './cognitoConfig';
import Cookies from 'js-cookie';
import './SignIn.css';
import { createPortal } from 'react-dom';
import { useNavigate, Link } from 'react-router-dom';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

const COOKIE_OPTIONS = {
  domain: '.devfruit.live',
  path: '/',
  secure: true,
  sameSite: 'strict'
} as const;

const clearAuthCookies = () => {
  Cookies.remove('id_token', COOKIE_OPTIONS);
  Cookies.remove('access_token', COOKIE_OPTIONS);
  Cookies.remove('refresh_token', COOKIE_OPTIONS);
  Cookies.remove('aws_credentials', COOKIE_OPTIONS);
};

async function getAwsCredentials(idToken: string) {
  try {
    const credentials = await fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ 
        region: awsConfig.region,
        credentials: undefined
      }),
      identityPoolId: awsConfig.identityPoolId,
      logins: {
        [`cognito-idp.${awsConfig.region}.amazonaws.com/${awsConfig.userPoolId}`]: idToken
      }
    })();

    Cookies.set('aws_credentials', JSON.stringify(credentials), {
      ...COOKIE_OPTIONS,
      expires: 1
    });
  } catch (error) {
    console.error('Error getting AWS credentials:', error);
    throw error;
  }
}

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState<boolean>(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

  // New state variables for required attributes
  const [address, setAddress] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [locale, setLocale] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [givenName, setGivenName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCookieConsent, setShowCookieConsent] = useState<boolean>(true);
  const [isCheckingAuth, setIsCheckingAuth] = useState<boolean>(true);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const handleRedirectToApp = () => {
    window.location.href = 'https://app.devfruit.live';  // We keep this one because it's cross-domain
  };

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const user = new CognitoUser({
        Username: username,
        Pool: userPool
      });

      setCognitoUser(user); // Store the user for later use

      await new Promise((resolve, reject) => {
        user.authenticateUser(authenticationDetails, {
          onSuccess: async (result: CognitoUserSession) => {          
            
            const idToken = result.getIdToken().getJwtToken();
            
            // Set tokens
            Cookies.set('id_token', idToken, {
              ...COOKIE_OPTIONS,
              expires: 7
            });

            Cookies.set('access_token', result.getAccessToken().getJwtToken(), {
              ...COOKIE_OPTIONS,
              expires: 7
            });

            Cookies.set('refresh_token', result.getRefreshToken().getToken(), {
              ...COOKIE_OPTIONS,
              expires: 30
            });

            // Get and store AWS credentials
            await getAwsCredentials(idToken);

            handleRedirectToApp();
            resolve(null);
          },
          onFailure: (err) => {
            console.error('Authentication failure:', err);
            reject(err);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            setIsNewPasswordRequired(true);
            setCognitoUser(user);
            resolve(null);
          }
        });
      });
    } catch (err: any) {
      console.error('Authentication failed', err);
      setError(err.message || "An error occurred during sign in");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPasswordSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!cognitoUser) {
      setError("No active user session. Please try signing in again.");
      setIsLoading(false);
      return;
    }

    try {
      const userAttributes = {
        address,
        gender,
        locale,
        email,
        given_name: givenName,
        family_name: familyName,
        updated_at: Math.floor(Date.now() / 1000).toString(),
      };

      await new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: async (result) => {          

            // Set both tokens from the session
            Cookies.set('id_token', result.getIdToken().getJwtToken(), {
              ...COOKIE_OPTIONS,
              expires: 7
            });

            Cookies.set('access_token', result.getAccessToken().getJwtToken(), {
              ...COOKIE_OPTIONS,
              expires: 7
            });

            resolve(null);
            handleRedirectToApp();
          },
          onFailure: (err) => {
            console.error('New password challenge failure:', err);
            reject(err);
          }
        });
      });
    } catch (err: any) {
      console.error('Failed to change password', err);
      setError(err.message || "Failed to update password and attributes");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAcceptCookies = () => {
    setShowCookieConsent(false);
    // Optionally save to localStorage to remember user's choice
    localStorage.setItem('cookiesAccepted', 'true');
  };

  // Add useEffect to check if user has already accepted cookies
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) {
      setShowCookieConsent(false);
    }
  }, []);

  // Add this function to handle token refresh
  const refreshTokens = async () => {
    const refreshToken = Cookies.get('refresh_token');
    if (!refreshToken) return false;

    try {
      const cognitoUser = new CognitoUser({
        Username: '',
        Pool: userPool
      });

      const refreshCognitoToken = new CognitoRefreshToken({ 
        RefreshToken: refreshToken 
      });

      const session = await new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser.refreshSession(refreshCognitoToken, (err, session) => {
          if (err) reject(err);
          else resolve(session);
        });
      });

      // Set new tokens
      Cookies.set('id_token', session.getIdToken().getJwtToken(), {
        ...COOKIE_OPTIONS,
        expires: 7
      });
      
      Cookies.set('access_token', session.getAccessToken().getJwtToken(), {
        ...COOKIE_OPTIONS,
        expires: 7
      });

      // Get and set new AWS credentials
      await getAwsCredentials(session.getIdToken().getJwtToken());
      
      return true;
    } catch (error) {
      console.error('Failed to refresh tokens:', error);
      clearAuthCookies();
      return false;
    }
  };

  // Modify the existing useEffect
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = Cookies.get('id_token');
        
        if (!token) {
          setIsCheckingAuth(false);
          return;
        }

        // Check if token is expired
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        const expiresIn = payload.exp - currentTime;
        
        // If token expires in less than 5 minutes, try to refresh
        if (expiresIn < 300) {
          setIsRefreshing(true);
          const refreshed = await refreshTokens();
          if (refreshed) {
            handleRedirectToApp();
            return;
          }
          clearAuthCookies();
        } else {
          // Token is valid
          setIsRedirecting(true);
          handleRedirectToApp();
          return;
        }
        
        setIsCheckingAuth(false);
      } catch (error) {
        console.error('Error checking auth:', error);
        clearAuthCookies();
        setIsCheckingAuth(false);
      }
    };

    checkAuth();
  }, []);

  // Add loading state for refresh
  if (isRefreshing) {
    return (
      <div className="page-container">
        <div className="auth-check-container">
          <h1 className="main-title">Fruitcount</h1>
          <div className="redirect-message">
            <div className="loading-spinner"></div>
            <p>Refreshing your session...</p>
          </div>
        </div>
      </div>
    );
  }

  if (isCheckingAuth) {
    return (
      <div className="page-container">
        <div className="auth-check-container">
          <h1 className="main-title">Fruitcount</h1>
          <div className="redirect-message">
            <div className="loading-spinner"></div>
            <p>Checking your session...</p>
          </div>
        </div>
      </div>
    );
  }

  if (isRedirecting) {
    return (
      <div className="page-container">
        <div className="auth-check-container">
          <h1 className="main-title">Fruitcount</h1>
          <div className="redirect-message">
            <div className="loading-spinner"></div>
            <p>Redirecting to dashboard...</p>
          </div>
        </div>
      </div>
    );
  }

  if (isNewPasswordRequired) {
    return (
      <div className="form-container">
        <h2>Complete Your Profile</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleNewPasswordSubmit}>
          <div>
            <label>New Password</label>
            <input 
              type="password" 
              value={newPassword} 
              onChange={(e) => setNewPassword(e.target.value)} 
              placeholder="Enter your new password"
              required 
            />
          </div>
          <div>
            <label>Email</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="your.email@example.com"
              required 
            />
          </div>
          <div>
            <label>Given Name</label>
            <input 
              type="text" 
              value={givenName} 
              onChange={(e) => setGivenName(e.target.value)} 
              placeholder="Your first name"
              required 
            />
          </div>
          <div>
            <label>Family Name</label>
            <input 
              type="text" 
              value={familyName} 
              onChange={(e) => setFamilyName(e.target.value)} 
              placeholder="Your last name"
              required 
            />
          </div>
          <div>
            <label>Address</label>
            <input 
              type="text" 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              placeholder="Your full address"
              required 
            />
          </div>
          <div>
            <label>Gender</label>
            <select 
              value={gender} 
              onChange={(e) => setGender(e.target.value)} 
              required
            >
              <option value="">Select your gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div>
            <label>Locale</label>
            <input 
              type="text" 
              value={locale} 
              onChange={(e) => setLocale(e.target.value)} 
              placeholder="e.g., en-US"
              required 
            />
          </div>
          <button 
            type="submit" 
            className={isLoading ? 'loading' : ''}
            disabled={isLoading}
          >
            {isLoading ? 'Setting Up Your Account...' : 'Complete Setup'}
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="page-container">
      <h1 className="main-title">Sign In to FruitCount</h1>
      <div className="form-container">
        <h2>Welcome Back</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignIn}>
          <div>
            <label>Username</label>
            <input 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              placeholder="Enter your username"
              required 
            />
          </div>
          <div>
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Enter your password"
              required 
            />
          </div>
          <button 
            type="submit" 
            className={isLoading ? 'loading' : ''}
            disabled={isLoading}
          >
            {isLoading ? 'Signing In...' : 'Sign In'}
          </button>
        </form>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Link 
            to="/about" 
            style={{ 
              color: 'var(--primary-color)', 
              textDecoration: 'none',
              fontSize: '14px'
            }}
          >
            About FruitCount
          </Link>
        </div>
      </div>

      {/* Move cookie consent outside page container and use portal */}
      {showCookieConsent &&
        createPortal(
          <div className="cookie-consent">
            <p>
              This website uses cookies for technical purposes to ensure the best possible experience.
            </p>
            <button onClick={handleAcceptCookies}>
              Accept
            </button>
          </div>,
          document.body
        )
      }
    </div>
  );
};

export default SignIn;
